<template>
  <v-container class=" fullscreen-bg" fluid> 
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card class="elevation-12">
          <EventHeader v-if="event" :event="event" compact/>
          <v-card-title class="headline">Admin Invite</v-card-title>
          <v-alert v-if="!isValid" type="error" tile>
            Invalid link. Please re-open the invite link.
          </v-alert>
          <v-card-text v-else-if="!event || (user && !profile)" class="text-center">
            <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
          </v-card-text>
          <v-alert v-else-if="profile" type="success" tile>
            <p>
              You are currently logged in as <strong>{{profile.email}}</strong> (profile ID: {{ profile.id }}).
            </p>
            <p>
              Want to accept this invite with another account? Please log out first and then re-open the link.
            </p>
          </v-alert>
          <v-card-text v-if="!user">
            <p>Please create an account first or login if you already have an account.</p>
            <p>
              <v-btn color="primary" @click="$refs.loginForm.open($route.query.email)" >Create Account or Login</v-btn>  
            </p>
          </v-card-text>

          <div v-if="isValid && user && profile" class="pa-4 align-right">
            <v-btn color="primary" @click="accept" :loading="$store.getters.isLoading">Accept invite</v-btn>  
          </div>

        </v-card>
      </v-col>
    </v-row>

    <LoginOrRegisterDialog ref="loginForm" mode="REGISTER" message="In order to accept this invite you'd need to either create a new account or login with your current account." />

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import EventHeader from '@/components/EventHeader.vue';
import LoginOrRegisterDialog from '@/components/LoginOrRegisterDialog.vue';
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  components: {
    EventHeader,
    LoginOrRegisterDialog,
  },
  props: {
  },
  data: () => ({
    profile: null,
    event: null,
  }),

  async mounted() {
    EventBus.$on('login-state-change', async user => {
      if (user) {
        await this.showProfileInfo();
      }
    });
    this.showProfileInfo();
  },

  methods: {
    async showProfileInfo() {
      this.event = (await eventService.get(this.$route.query.eventId, /*draft:*/ true)).data;
      if (this.user) {
        this.profile = (await profileService.get()).data;
      }
    },

    async accept() {
      if (this.$route.query.email && this.$route.query.token) {
        const response = (await eventManagerService.acceptAdminInvite(this.$route.query.eventId, this.$route.query.email, this.$route.query.token)).data;
        this.$helpers.toastResponse(this, response, 'Invite accepted successfully.');
        if (response.status == 'OK') {
          this.$router.push({ name: 'eventmanagerView', params: { id: this.$route.query.eventId } });
        }
      }
    },
  
  },
  computed: {
    isValid() {
      return this.$route.query.token && this.$route.query.eventId && this.$route.query.email;
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss" scoped>
  .fullscreen-bg {
    margin-top: 60px;
    padding-top: 40px;
    background-size: cover;
    background-position: 50%;

    .row { margin: 0!important; }
  }
  .view--embedded .fullscreen-bg { margin-top: 0;}

  .provider-container {
    display: flex;
    flex-direction: column;

  }

</style>

